import { css } from 'styled-components';
import { breakpoints } from '../../style-utilities/variables';

export default css`
  .favorite {
    margin-right: 0.5em;
  }

  button {
    svg {
      margin-right: 0.5em;
    }
  }
  .search {
    z-index: 5;
  }
  .opacity:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .InnerBox {
    z-index: 5;
    .title {
      width: 100%;
      @media (min-width: ${breakpoints.tablet}) {
        width: 700px;
      }
    }
  }

  .collection-section {
    max-width: 80%;
    .display-recipes {
      display: block !important;
      margin: 2rem auto;
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    .collection-section {
      max-width: 100%;
      .display-recipes {
        margin: auto;
      }
    }
  }
`;
