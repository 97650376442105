import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { navigate } from '@reach/router';
import { Helmet } from 'react-helmet';
import ComponentStyles from '../brand-styles/templates/recipe-collection-detail.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';
import ShareWidget from '../components/ShareWidget';
import PagesHero from '../components/PagesHero';
import Heading from '../components/Heading';
import SubHeading from '../components/SubHeading';
import StyledLink from '../components/StyledLink';
import RecipeSearchWidget from '../components/RecipeSearchWidget';
import RecipeCard from '../components/RecipeCard';
import placeHolderImage from '../images/placeholder.png';
import { breakpoints, colors } from '../style-utilities/variables';
import { stripHtmlTag } from '../utilities/stripHtmlTagUtils';
import RegularButton from '../components/RegularButton';
import FavoritesService from '../services/favorites.service';
import UserService from '../services/user.service';
import FlyoutBasic from '../components/FlyoutBasic';
import FlyoutContentNotAuthed from '../components/FlyoutContentNotAuthed';

import SEO from '../components/SEO';
import { getTime } from 'date-fns';

const Div = styled.div`
  overflow-x: none;
  .search {
    display: none;
    position: absolute;
    margin: 3rem;
    @media (min-width: ${breakpoints.tablet}) {
      display: block;
    }
  }

  .InnerBox {
    display: -ms-inline-flexbox;
    .title {
      /* width: 50%; */
      margin: 0 auto;
      background: rgb(
        204,
        204,
        204
      ); /* Fallback for older browsers without RGBA-support */
      background: rgba(204, 204, 204, 0.7);
      z-index: 0;
      margin-bottom: 20px;
      h1 {
        margin: 0;
        padding: 15px 20px;
        color: ${colors.secondary};
        font-size: 40px;
        z-index: 4;
      }
      p {
        margin: 0;
        padding: 15px 20px;
      }
      @media (max-width: 799px) {
        width: 100%;
        padding: 1em 2em;
      }
    }
  }

  .widget-section {
    display: block;
    text-align: center;
    background: #ebebeb;
    padding: 20px;
    border-left: none;
    border-right: none;
  }
  @media (min-width: ${breakpoints.tablet}) {
    .see-all {
      display: block;
      position: absolute;
      top: 40px;
      right: 80px;
    }
    .widget-section {
      display: flex;
      align-items: center;
      justify-content: center;
      .widget {
        padding-left: 0;
      }
      .favorite {
        margin-top: 0;
        svg {
          margin-right: 5px;
        }
      }
    }
  }

  .collection-section {
    text-align: center;
    padding: 40px;
    max-width: 1280px;
    margin: 0 auto;
    .recipe-card {
      vertical-align: top;
      margin: 0 10px;
    }
    .display-recipes {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media (min-width: ${breakpoints.mobileMax}) {
        justify-content: flex-start;
      }
    }

    .see-all-button {
      margin: auto;
    }
    @media (min-width: ${breakpoints.mobile}) {
      .recipe-card {
        margin: 20px;
      }
    }
    @media (min-width: ${breakpoints.tablet}) {
      .hide-desktop {
        display: none;
      }
    }
  }

  ${ComponentStyles}
`;

class RecipeCollectionDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewPort: 'desktop',
      isBrowser: true,
      searchValue: '',
      refreshFavorites: false,
      isAuthed: false,
      showNoAuthFlyout: false,
      allFavorited: false,
      totalFavorites: null,
      previewPayload: null,
      isPreviewMode: false
    };

    this.favoriteService = new FavoritesService();
    this.userService = new UserService();
  }

  recipeCollection = this.props.data.wordpressWpRecipeCollections;
  allRecipes = this.props.data.allWordpressWpRecipes.edges;
  /**
   * METHOD: Render recipes from the collection
   */

  componentDidUpdate(prevPros) {
    if (
      this.props.userContext.authenticatedUser !==
      prevPros.userContext.authenticatedUser
    ) {
      this.setState({
        isAuthed: true
      });
    }
  }
  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const user = this.userService.getAuthenticatedUser();
    this.setState({
      isAuthed: user ? true : false,
      isBrowser: typeof window !== 'undefined'
    });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.setViewport);
    }
    this.setState({
      viewPort:
        this.state.isBrowser && window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });

    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `${process.env.WP_BASE_URI}/${
        process.env.ACTIVE_BRAND
      }/wp-json/wp/v2`;

      fetch(
        `${BASE_URL}/recipe_collections/${id}/revisions/?_wpnonce=${wpnonce}`,
        {
          mode: 'cors',
          credentials: 'include'
        }
      )
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
    this.refresh();
  }

  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.setViewport);
    }
  }
  setViewport = () => {
    this.setState({
      viewPort:
        this.state.isBrowser && window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
  };
  toggleShowNoAuthFlyout = value => {
    this.setState({
      showNoAuthFlyout: value
    });
  };

  refresh = () => {
    this.getFavoritesForUser();
  };

  getFavoritesForUser = () => {
    const user = this.userService.getAuthenticatedUser();
    const currentRecipes = this.getCurrentRecipes();

    if (user) {
      this.favoriteService
        .getFavoritesForUser(user, 'recipes')
        .then(favorites => {
          if (favorites) {
            //this.props.userContext.updateFavoritesCount(favorites.length);
            const filteredFavs = favorites.filter(el =>
              currentRecipes.some(e => el.slug === e.node.slug)
            );
            this.setState({
              totalFavorites: filteredFavs.length,
              allFavorited:
                filteredFavs.length === currentRecipes.length ? true : false
            });
          }
        });
    }
  };

  getCurrentRecipes = () => {
    return this.allRecipes.filter(el =>
      this.recipeCollection.acf.recipes.some(
        e => e.recipe.post_name === el.node.slug
      )
    );
  };

  renderRecipes = (refreshFavorites, itemsToRemove) => {
    // Get nested acf for recipes in the current collection
    const currentRecipes = this.getCurrentRecipes();
    return currentRecipes.map(({ node: { title, slug, acf } }) => {
      return (
        <RecipeCard
          updateFavoritesCount={this.props.userContext.updateFavoritesCount}
          authenticatedUser={this.props.userContext.authenticatedUser}
          className="recipe-card"
          id={slug}
          key={slug}
          title={title}
          imageUrl={
            acf.thumbnail === null ? placeHolderImage : acf.thumbnail.source_url
          }
          // mainIngredient={acf.main_ingredient}
          // cuisine={acf.cuisine}
          // meal={acf.meal_course}
          cookTime={acf.total_time}
          slug={slug}
          refreshFavorites={refreshFavorites}
        />
      );
    });
  };

  onChangeSearchHandler = event => {
    const value = event.target.value;
    this.setState({ searchValue: value });
  };

  summitSearchHanlder = e => {
    e.preventDefault();
    this.setState(() => {
      navigate(`/recipe-search?includes=${this.state.searchValue}`);
    });
  };

  saveAllRecipesAsFavorites = e => {
    e.preventDefault();
    const user = this.userService.getAuthenticatedUser();
    const type = 'recipes';

    const currentRecipes = this.getCurrentRecipes();
    const items = currentRecipes.map(recipe => {
      return {
        slug: recipe.node.slug
      };
    });
    if (user && items.length) {
      this.favoriteService.addAllFavorites(user, items, type).then(result => {
        this.favoriteService.getFavoritesForUser(user, type).then(res => {
          if (res) {
            this.props.userContext.updateFavoritesCount(res.length);
            //refresh all recipes
            this.setState(prevState => ({
              refreshFavorites: !prevState.refreshFavorites,
              allFavorited: true
            }));
          }
        });
      });
    } else if (!user) {
      //show no auth flyout
      this.setState({
        isAuthed: false
      });

      this.toggleShowNoAuthFlyout(true);
    }
  };

  removeAllRecipesAsFavorites = e => {
    e.preventDefault();
    const user = this.userService.getAuthenticatedUser();
    const currentRecipes = this.getCurrentRecipes();
    const type = 'recipes';
    const items = currentRecipes.map(recipe => {
      return {
        slug: recipe.node.slug
      };
    });
    if (user && items.length) {
      items.map(item => {
        this.favoriteService
          .toggleFavorite(user, item, 'recipes')
          .then(result => {
            if (result) {
              //this.props.userContext.updateFavoritesCount(result.length);
              //refresh all recipes
              this.setState(
                prevState => ({
                  refreshFavorites: !prevState.refreshFavorites,
                  allFavorited: false
                }),
                () => {
                  this.favoriteService
                    .getFavoritesForUser(user, type)
                    .then(result => {
                      if (result) {
                        this.props.userContext.updateFavoritesCount(
                          result.length
                        );
                      }
                    });
                }
              );
            }
          });
      });
    }
  };

  render() {
    let title, content, acf, heroImage;

    if (this.state.isPreviewMode) {
      title = this.state.previewPayload.title.rendered;
      content = this.state.previewPayload.content.rendered;
      acf = this.state.previewPayload.acf;
      heroImage =
        acf.hero_image === null ? placeHolderImage : acf.hero_image.url;
    } else {
      title = this.props.data.wordpressWpRecipeCollections.title;
      content = this.props.data.wordpressWpRecipeCollections.content;
      acf = this.props.data.wordpressWpRecipeCollections.acf;
      heroImage =
        acf.hero_image === null ? placeHolderImage : acf.hero_image.source_url;
    }

    const recipeSearchBackground = this.props.data.wordpressPage.acf
      .search_widget_image.source_url;

    const {
      facebook_url,
      instagram_url,
      youtube_url,
      twitter_url,
      pinterest_url
    } = this.props.data.wordpressWpOptionsPage.acf;

    //configure SEO info for header
    const backupContent = this.props.data.wordpressWpRecipeCollections.content.replace(
      /<\/?[^>]+(>|$)/g,
      ''
    );
    const seoInfo = {
      psKey: false,
      title: this.props.data.wordpressWpRecipeCollections.yoast_head_json
        .title,
      ogtitle: this.props.data.wordpressWpRecipeCollections.title,
      description:
        this.props.data.wordpressWpRecipeCollections.yoast_head_json
          .description !== ''
          ? this.props.data.wordpressWpRecipeCollections.yoast_head_json
              .description
          : backupContent,
      url: this.props.location.href,
      type: 'website',
      twitter: twitter_url,
      image:
        acf.hero_image !== null
          ? this.state.isPreviewMode
            ? acf.hero_image.url
            : acf.hero_image.source_url
          : placeHolderImage
    };

    return (
      <Div>
        <SEO seoInfo={seoInfo} />

        <div className="search">
          <StyledLink link="/recipe-collections">
            See All Collections
          </StyledLink>
        </div>
        <PagesHero className="hero-section" image={heroImage}>
          {this.props.siteTitle === 'mahatma' ? (
            <div className="smokeBlur" />
          ) : (
            ''
          )}
          <div className="InnerBox">
            <div className="title">
              <h1>{ReactHtmlParser(title)}</h1>
              {ReactHtmlParser(content)}
            </div>
          </div>
        </PagesHero>

        <div className="widget-section">
          <ShareWidget
            className="widget"
            fbMessengerPageName={title}
            hideAPinButton={false}
            pinterestTitle={title}
            pinterest_image_url={heroImage}
          />
          <div>
            <FlyoutBasic
              styles={
                this.state.viewPort === 'desktop'
                  ? {
                      visible:
                        !this.state.isAuthed && this.state.showNoAuthFlyout,
                      height: '250px',
                      caretRight: '163px'
                    }
                  : {
                      visible:
                        !this.state.isAuthed && this.state.showNoAuthFlyout,
                      height: '250px',
                      caretRight: '163px'
                    }
              }
              onClose={this.toggleShowNoAuthFlyout.bind(this, false)}
              locationId="recipe-collection-detail-flyout"
            >
              <FlyoutContentNotAuthed title="Save & Favorite Recipes" />
            </FlyoutBasic>
          </div>
          {!this.state.allFavorited ? (
            <RegularButton
              className="button favorite"
              onClick={this.saveAllRecipesAsFavorites}
            >
              <FontAwesomeIcon
                icon={faHeart}
                style={{ width: '16px', height: '16px' }}
              />
              Save all {this.getCurrentRecipes().length} Recipes as Favorites
            </RegularButton>
          ) : (
            <RegularButton
              className="button favorite"
              onClick={this.removeAllRecipesAsFavorites}
            >
              <FontAwesomeIcon
                icon={faHeartSolid}
                style={{ width: '16px', height: '16px' }}
              />
              Remove all {this.getCurrentRecipes().length} Recipes as Favorites
            </RegularButton>
          )}
        </div>
        <div className="collection-section">
          <div className="hide-desktop">
            <Heading>{ReactHtmlParser(title)}</Heading>
            <SubHeading>{this.getCurrentRecipes().length} Recipes</SubHeading>
          </div>

          <div className="display-recipes">
            {this.renderRecipes(
              this.state.refreshFavorites,
              this.state.itemsToRemove
            )}
          </div>

          <StyledLink link="/recipe-collections">
            See all Recipe Collections
          </StyledLink>
        </div>
        <RecipeSearchWidget
          background={recipeSearchBackground}
          clicked={this.summitSearchHanlder}
          changeHandler={this.onChangeSearchHandler}
        />
      </Div>
    );
  }
}

export default RecipeCollectionDetail;

export const query = graphql`
  query($slug: String!) {
    wordpressWpRecipeCollections(slug: { eq: $slug }) {
      title
      content
      slug
      yoast_head_json {
        title
        description
      }
      acf {
        hero_image {
          source_url
        }
        recipes {
          recipe {
            post_name
            post_title
            wordpress_id
          }
        }
      }
    }
    wordpressWpOptionsPage(slug: { eq: "site-options" }) {
      acf {
        facebook_url
        instagram_url
        youtube_url
        twitter_url
        pinterest_url
      }
    }
    allWordpressWpRecipes {
      edges {
        node {
          title
          slug
          acf {
            thumbnail {
              source_url
            }
            total_time
          }
        }
      }
    }
    wordpressPage(slug: { eq: "recipe-collection-detail" }) {
      acf {
        search_widget_image {
          source_url
        }
      }
    }
  }
`;
